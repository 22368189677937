import { throttle } from '@fu/core'
export function loadScript(url) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = url

    script.onload = () => {
      resolve()
    }

    script.onerror = () => {
      console.error(`Failed to load script: ${url}`)
      reject(new Error(`Failed to load script: ${url}`))
    }

    document.head.appendChild(script)
  })
}

// export async function fetchWithProgress(url, onProgress) {
//   const response = await fetch(url)

//   if (!response.ok) {
//     throw new Error(`Failed to fetch ${url}: ${response.statusText}`)
//   }

//   const contentLength = response.headers.get('Content-Length')
//   if (!contentLength) {
//     throw new Error('Content-Length response header unavailable')
//   }

//   const total = parseInt(contentLength, 10)
//   let loaded = 0

//   const reader = response.body.getReader()
//   const chunks = []

//   // eslint-disable-next-line no-constant-condition
//   while (true) {
//     const res = await reader.read()
//     const { done, value } = res

//     if (done) {
//       console.log('continue')
//       break
//     }
//     chunks.push(value)
//     loaded += value.length
//     onProgress(loaded, total)
//   }

//   return new Uint8Array(chunks.reduce((acc, chunk) => acc.concat(Array.from(chunk)), []))
// }

// 尝试加载onnx
export async function fetchWithProgress(url, onProgress) {
  const response = await fetch(url)
  if (!response.ok) {
    throw new Error(`Failed to fetch ${url}: ${response.statusText}`)
  }

  const contentLength = parseInt(response.headers.get('Content-Length') || '0', 10)
  if (isNaN(contentLength)) {
    throw new Error('Content-Length response header unavailable or invalid')
  }

  const totalBytes = contentLength
  const ab = new ArrayBuffer(totalBytes) // 预先分配整个缓冲区
  let offset = 0

  const reader = response.body.getReader()

  return new Promise((resolve, reject) => {
    function read() {
      reader.read().then(({ done, value }) => {
        if (done) {
          resolve(new Uint8Array(ab)) // 返回整个 ArrayBuffer 的 Uint8Array 视图
          return
        }

        const view = new Uint8Array(ab, offset, value.byteLength)
        view.set(value) // 将读取的数据块复制到 ArrayBuffer 的相应位置
        offset += value.byteLength

        if (onProgress) {
          onProgress(offset, totalBytes)
        }

        read() // 递归读取下一个数据块
      }).catch(reject)
    }

    read() // 开始读取数据
  })
}

export function devicemotionHandler(callback) {
  if (window.deviceorientationEventBind) return
  window.deviceorientationEventBind = true

  const throttleFunc = throttle(function (event) {
    console.log('已经有传感器', event)
    const gamma = event.gamma
    const beta = event.beta
    if (Math.abs(gamma) > 45 && Math.abs(beta) < 45) {
      console.log('手机横着拿')
      callback()
    }
  }, 1000)

  if (typeof DeviceOrientationEvent !== 'undefined' && typeof DeviceOrientationEvent.requestPermission === 'function') {
    DeviceOrientationEvent.requestPermission().then(permissionState => {
      if (permissionState === 'granted') {
        window.addEventListener('deviceorientation', (event) => {
          throttleFunc(event)
        })
      }
    }).catch(console.error)
  } else {
    window.addEventListener('deviceorientation', (event) => {
      throttleFunc(event)
    })
  }
}

export class Colors {
  // ultralytics color palette https://ultralytics.com/
  constructor() {
    this.palette = [
      '#FF3838',
      '#FF9D97',
      '#FF701F',
      '#FFB21D',
      '#CFD231',
      '#48F90A',
      '#92CC17',
      '#3DDB86',
      '#1A9334',
      '#00D4BB',
      '#2C99A8',
      '#00C2FF',
      '#344593',
      '#6473FF',
      '#0018EC',
      '#8438FF',
      '#520085',
      '#CB38FF',
      '#FF95C8',
      '#FF37C7'
    ]
    this.n = this.palette.length
  }

  get = (i) => this.palette[Math.floor(i) % this.n];

  static hexToRgba = (hex, alpha) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result
      ? `rgba(${[parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)].join(
        ', '
      )}, ${alpha})`
      : null
  };
}

export function getMaxArea(boxes) {
  let maxArea = 0
  let maxBox = null

  boxes.forEach(function (box) {
    const width = box.bounding[2]
    const height = box.bounding[3]
    const area = width * height

    if (area > maxArea) {
      maxArea = area
      maxBox = box
    }
  })

  return maxBox ? [maxBox] : []
}

export function calculatePadding(canvas, rect, threshold) {
  if (!canvas) return []
  var canvasWidth = canvas.width
  var canvasHeight = canvas.height

  var x = rect[0]
  var y = rect[1]
  var width = rect[2]
  var height = rect[3]

  var paddingLeft = x
  var paddingRight = canvasWidth - (x + width)
  var paddingTop = y
  var paddingBottom = canvasHeight - (y + height)

  return [
    paddingTop < threshold,
    paddingRight < threshold,
    paddingBottom < threshold,
    paddingLeft < threshold
  ]
}

export function drawArrow(context, fromX, fromY, toX, toY, arrowWidth, arrowHeadLength) {
  var dx = toX - fromX
  var dy = toY - fromY
  var angle = Math.atan2(dy, dx)

  context.lineWidth = arrowWidth // 设置线条宽度

  // 绘制箭头主干
  context.beginPath()
  context.moveTo(fromX, fromY)
  context.lineTo(toX, toY)
  context.stroke()

  // 保存当前的绘图状态
  context.save()

  // 移动到箭头末端
  context.translate(toX, toY)
  context.rotate(angle)

  // 绘制箭头头部
  context.beginPath()
  context.moveTo(0, 0)
  context.lineTo(-arrowHeadLength, arrowWidth / 2)
  context.lineTo(-arrowHeadLength, -arrowWidth / 2)
  context.closePath()

  context.fill()
  context.stroke()

  // 恢复绘图状态
  context.restore()
}
